import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/store';

import { resetAll } from '@features/common';

import { fetchMyProfile } from './profile.thunks';
import { ProfileState } from './profile.types';

const initialState: ProfileState = {
  value: null,
  isFetching: false,
  fetchComplete: false,
  errorOccurred: false,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(fetchMyProfile.pending.type, (state: ProfileState) => {
      state.isFetching = true;
      state.errorOccurred = false;
      delete state.errorStatusCode;
    });
    builder.addCase(fetchMyProfile.fulfilled.type, (state: ProfileState, action: any) => {
      state.isFetching = false;
      state.fetchComplete = true;
      state.errorOccurred = false;
      delete state.errorStatusCode;

      state.value = action.payload;
    });
    builder.addCase(fetchMyProfile.rejected.type, (state: ProfileState, action: any) => {
      const payloadJson = action.payload;
      const parsedPayload = payloadJson ? JSON.parse(payloadJson) : null;

      state.isFetching = false;
      state.errorOccurred = true;
      state.errorStatusCode = parsedPayload?.status || 500;
    });
    builder.addCase(resetAll, () => initialState);
  },
});

export const selectProfile = (state: RootState) => state.me;

export const meReducer = profileSlice.reducer;
